import jQuery from 'jquery'

jQuery(document).ready(function ($) {
  if ($('#editer_annonce_redirectionEmail').length > 0) {
    if ($('#editer_annonce_redirectionEmail').val().length > 0) {
      $('#editer_annonce_redirectionUrl').prop('readonly', true)
      $('#editer_annonce_redirectionUrl').prop('required', false)
      $('#editer_annonce_redirectionUrl').val('')
      $('#editer_annonce_redirectionUrl').closest('.form-group').removeClass('error')
    } else {
      $('#editer_annonce_redirectionUrl').prop('readonly', false)
      $('#editer_annonce_redirectionUrl').prop('required', true)
    }

    if ($('#editer_annonce_redirectionUrl').val().length > 0) {
      $('#editer_annonce_redirectionEmail').prop('readonly', true)
      $('#editer_annonce_redirectionEmail').prop('required', false)
      $('#editer_annonce_redirectionEmail').closest('.form-group').removeClass('error')
    } else {
      $('#editer_annonce_redirectionEmail').prop('readonly', false)
      $('#editer_annonce_redirectionEmail').prop('required', true)
    }
  }

  $('body').on('change', '#editer_annonce_redirectionEmail', function () {
    if ($(this).val().length > 0) {
      $('#editer_annonce_redirectionUrl').prop('readonly', true)
      $('#editer_annonce_redirectionUrl').prop('required', false)
      $('#editer_annonce_redirectionUrl').closest('.form-group').removeClass('error')
    } else {
      $('#editer_annonce_redirectionUrl').prop('readonly', false)
      $('#editer_annonce_redirectionUrl').prop('required', true)
    }
  })

  $('body').on('change', '#editer_annonce_redirectionUrl', function () {
    if ($(this).val().length > 0) {
      $('#editer_annonce_redirectionEmail').prop('readonly', true)
      $('#editer_annonce_redirectionEmail').prop('required', false)
      $('#editer_annonce_redirectionEmail').closest('.form-group').removeClass('error')
    } else {
      $('#editer_annonce_redirectionEmail').prop('readonly', false)
      $('#editer_annonce_redirectionEmail').prop('required', true)
    }
  })

  if ($('#publier_annonce_redirectionEmail').length > 0) {
    if ($('#publier_annonce_redirectionEmail').val().length > 0) {
      $('#publier_annonce_redirectionUrl').prop('readonly', true)
      $('#publier_annonce_redirectionUrl').prop('required', false)
      $('#publier_annonce_redirectionUrl').closest('.form-group').removeClass('error')
      $('#publier_annonce_redirectionUrl').val('')
    } else {
      $('#publier_annonce_redirectionUrl').prop('readonly', false)
      $('#publier_annonce_redirectionUrl').prop('required', true)
    }

    if ($('#publier_annonce_redirectionUrl').val().length > 0) {
      $('#publier_annonce_redirectionEmail').prop('readonly', true)
      $('#publier_annonce_redirectionEmail').prop('required', false)
      $('#publier_annonce_redirectionEmail').closest('.form-group').removeClass('error')
    } else {
      $('#publier_annonce_redirectionEmail').prop('readonly', false)
      $('#publier_annonce_redirectionEmail').prop('required', true)
    }
  }

  $('body').on('change', '#publier_annonce_redirectionEmail', function () {
    if ($(this).val().length > 0) {
      $('#publier_annonce_redirectionUrl').prop('readonly', true)
      $('#publier_annonce_redirectionUrl').prop('required', false)
      $('#publier_annonce_redirectionUrl').closest('.form-group').removeClass('error')
    } else {
      $('#publier_annonce_redirectionUrl').prop('readonly', false)
      $('#publier_annonce_redirectionUrl').prop('required', true)
    }
  })

  $('body').on('change', '#publier_annonce_redirectionUrl', function () {
    if ($(this).val().length > 0) {
      $('#publier_annonce_redirectionEmail').prop('readonly', true)
      $('#publier_annonce_redirectionEmail').prop('required', false)
      $('#publier_annonce_redirectionEmail').closest('.form-group').removeClass('error')
    } else {
      $('#publier_annonce_redirectionEmail').prop('readonly', false)
      $('#publier_annonce_redirectionEmail').prop('required', true)
    }
  })
})
