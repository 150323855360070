import jQuery from 'jquery'

jQuery(document).ready(function($) {
    if ($('#candidat_mon_compte').length > 0) {
        $('body').on('click', '#add-cv', function() {
            $('#user_candidat_mon_compte_cvFile_file').trigger('click')
        })
        $('body').on('click', '#delMonCompte', function() {
            $('#delMonCompteAlert').addClass('show')
        })
        $('body').on('click', '#cvMonCompte', function() {
            $('#cvMonCompteAlert').addClass('show')
        })
        $('#user_candidat_mon_compte_cvFile_file').on('change', function() {
            var filename = $(this).val()
            var lastIndex = filename.lastIndexOf('\\')
            if (lastIndex >= 0) {
                filename = filename.substring(lastIndex + 1)
            }
            $('input[name="fake_cv"]').val(filename)
        })
    }
    let a
    if ($('form[name="annonce_postuler"]').length) {
        a = 'annonce_postuler'
    }
    else {
        a = 'annonce_postulation_rapide'
    }

    const baseId = a;

    if ($('#annonce').length > 0) {
        $('body').on('click', '#add-cv', function() {
            $(`#${baseId}_cvFile_file`).trigger('click')
        })
        $('body').on('click', '#delMonCompte', function() {
            $('#delMonCompteAlert').addClass('show')
        })
        $('body').on('click', '#cvMonCompte', function() {
            $('#cvMonCompteAlert').addClass('show')
        })
        $(`#${baseId}_cvFile_file`).on('change', function() {
            var filename = $(this).val()
            var lastIndex = filename.lastIndexOf('\\')
            if (lastIndex >= 0) {
                filename = filename.substring(lastIndex + 1)
            }
            $('input[name="fake_cv"]').val(filename)
        })
    }
    if ($('#annonce').length > 0) {
        var $collectionHolder;

        var $addTagButton = $('<button  class="bt_cv" id="bt_doc" type="button" name="cv">Ajouter un document</button>');
        var $newLinkLi = $('<li></li>').append($addTagButton);
        $collectionHolder = $('ul.documents');

        $collectionHolder.append($newLinkLi);

        $collectionHolder.data('index', $collectionHolder.find(':input').length);

        $addTagButton.on('click', function(e) {
            addDocForm($collectionHolder, $newLinkLi);
        });

        function addDocForm($collectionHolder, $newLinkLi) {
            // Get the data-prototype explained earlier
            var prototype = $collectionHolder.data('prototype');

            // get the new index
            var index = $collectionHolder.data('index');

            var newForm = prototype;
            newForm = newForm.replace(/__name__/g, index);
            $collectionHolder.data('index', index + 1);
            var $newFormLi = $('<li></li>').append(newForm);
            $newFormLi.find(".vich-file").hide();
            $newFormLi.find(`#${baseId}_candidatDocuments_` + index + "_document").closest('.form-group').hide();
            $newLinkLi.before($newFormLi);
            $("<i class='material-icons'>delete</i>").insertAfter(`#${baseId}_candidatDocuments_` + index + "_document");
            $(`#${baseId}_candidatDocuments_` + index + '_documentFile_file').trigger('click');
        }
        $('ul.documents').on('change', 'input[type=file]', function() {

            var id = $(this).attr('id');
            var input = id.replace("File_file", '');
            var filename = $(this).val()
            var lastIndex = filename.lastIndexOf('\\')
            if (lastIndex >= 0) {
                filename = filename.substring(lastIndex + 1)
            }
            $('#' + input).val(filename)
            $('#' + input).closest('.form-group').show();



        })

        $('ul.documents').on('click', 'i', function() {
            $(this).parent().parent().parent().remove();
        });
    }

})