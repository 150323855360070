import jQuery from 'jquery'
import './alert_html.js'

(function ($) {
  $.fn.html5cvm = function (options) {
    var settings = $.extend({
      valueMissing: null,
      typeMismatch: null,
      patternMismatch: null,
      tooLong: null,
      rangeUnderflow: null,
      rangeOverflow: null,
      stepMismatch: null,
      generic: null,
      alertDelay: 0,
      alert: false,
      alertMessage: '',
      alertType: 'danger',
      alertId: null,
      animate: true,
      animateType: 'pulse'
    }, options)

    return this.each(function () {
      var form = $(this).attr('name')

      $('form[name="' + form + '"] input').each(function (e) {
        if ($(this)[0].hasAttribute('maxlength') && $(this)[0].hasAttribute('data-show-length') && $(this).data('show-length') === true) {
          if ($(this).closest('.form-group').find('span.maxlength').length > 0) {
            $(this).closest('.form-group').find('span.maxlength').html('Nombre de caractère(s) ' + $(this).val().length + '/' + $(this).attr('maxlength'))
          } else {
            $(this).closest('.form-group').append('<span class="maxlength">Nombre de caractère(s) ' + $(this).val().length + '/' + $(this).attr('maxlength') + '</span>')
          }
        }
      })

      $('form[name="' + form + '"] input:not([type=hidden]):not([type=submit]), form[name="' + form + '"] select').on({
        invalid: function (e) {
          if (e.target.tagName.toUpperCase() === 'INPUT' || e.target.tagName.toUpperCase() === 'TEXTAREA') {
            e.target.setCustomValidity('')
          }

          if (!e.target.validity.valid) {
            var msg

            if ($(this).data('error-animate')) {
              settings.animate = false
              if ($(this).data('error-animate') === true) {
                settings.animate = true
              }
            }

            if ($(this).data('error-animate-type')) {
              settings.animateType = $(this).data('error-animate-type')
            }

            if ($(this).data('error-alert')) {
              settings.alert = false
              if ($(this).data('error-alert') === true || $(this).data('error-alert') === 'true') {
                settings.alert = true
                settings.alertId = $.now() + Math.floor(Math.random() * 500) + 1
              }
            }

            if ($(this).data('error-alert-id')) {
              settings.alertId = $(this).data('error-alert-id')
            }

            if ($(this).data('error-alert-delay')) {
              settings.alertDelay = $(this).data('error-alert-delay')
            }

            if ($(this).data('error-alert-message')) {
              settings.alertMessage = $(this).data('error-alert-message')
            }

            if ($(this).data('error-alert-type')) {
              settings.alertType = $(this).data('error-alert-type')
            }

            if (e.target.validity.valueMissing) {
              if ($(this).data('error-value-missing')) {
                msg = $(this).data('error-value-missing')
              } else {
                msg = settings.valueMissing
              }
            }

            if (e.target.validity.typeMismatch) {
              if ($(this).data('error-type-mismatch')) {
                msg = $(this).data('error-type-mismatch')
              } else {
                msg = settings.typeMismatch
              }
            }

            if (e.target.validity.patternMismatch) {
              if ($(this).data('error-pattern-mismatch')) {
                msg = $(this).data('error-pattern-mismatch')
              } else {
                msg = settings.patternMismatch
              }
            }

            if (e.target.validity.tooLong) {
              if ($(this).data('error-pattern-too-long')) {
                msg = $(this).data('error-pattern-too-long')
              } else {
                msg = settings.tooLong
              }
            }

            if (e.target.validity.rangeUnderflow) {
              if ($(this).data('error-pattern-range-underflow')) {
                msg = $(this).data('error-pattern-range-underflow')
              } else {
                msg = settings.rangeUnderflow
              }
            }

            if (e.target.validity.rangeOverflow) {
              if ($(this).data('error-pattern-range-overflow')) {
                msg = $(this).data('error-pattern-range-overflow')
              } else {
                msg = settings.rangeUOverflow
              }
            }

            if (e.target.validity.stepMismatch) {
              if ($(this).data('error-step-mismatch')) {
                msg = $(this).data('error-step-mismatch')
              } else {
                msg = settings.stepMismatch
              }
            }

            if (!msg) {
              if ($(this).data('error-generic')) {
                msg = $(this).data('error-generic')
              } else {
                msg = settings.generic
              }
            }

            if (msg) {
              if (e.target.tagName.toUpperCase() === 'INPUT' || e.target.tagName.toUpperCase() === 'TEXTAREA') {
                e.target.setCustomValidity(msg)
              }

              $(this).closest('.form-group').addClass('error')

              $(this).closest('.form-group').find('.statut').data('toggle', 'tooltip')
              $(this).closest('.form-group').find('.statut').data('placement', 'bottom')
              $(this).closest('.form-group').find('.statut').data('title', msg)
              $(this).closest('.form-group').find('.statut').tooltip()

              if (settings.animate === true) {
                $(this).addClass('animated ' + settings.animateType)
              }

              if (settings.alert === true && $('#' + settings.alertId).length === 0) {
                settings.alertMessage = msg
                $('body').prepend(
                  global.htmlAlert(settings.alertType, settings.alertId, settings.alertMessage)
                )
                if (settings.alertDelay > 0) {
                  setTimeout(function () {
                    $('#' + settings.alertId).fadeOut().remove()
                  }, settings.alertDelay)
                }
              }
            } else {
              $(this).closest('.form-group').removeClass('error')
            }
          } else {
            $(this).closest('.form-group').removeClass('error')
          }
        },
        change: function (e) {
          if (e.target.tagName.toUpperCase() === 'INPUT' || e.target.tagName.toUpperCase() === 'TEXTAREA') {
            e.target.setCustomValidity('')
          }

          if ($(this)[0].hasAttribute('maxlength') && $(this)[0].hasAttribute('data-show-length') && $(this).data('show-length') === true) {
            if ($(this).closest('.form-group').find('span.maxlength').length > 0) {
              $(this).closest('.form-group').find('span.maxlength').html('Nombre de caractère(s) ' + $(this).val().length + '/' + $(this).attr('maxlength'))
            } else {
              $(this).closest('.form-group').append('<span class="maxlength">Nombre de caractère(s) ' + $(this).val().length + '/' + $(this).attr('maxlength') + '</span>')
            }
          }

          if (e.target.validity && !e.target.validity.valid) {
            var msg

            if (e.target.validity.valueMissing) {
              if ($(this).data('error-value-missing')) {
                msg = $(this).data('error-value-missing')
              } else {
                msg = settings.valueMissing
              }
            }

            if (e.target.validity.typeMismatch) {
              if ($(this).data('error-type-mismatch')) {
                msg = $(this).data('error-type-mismatch')
              } else {
                msg = settings.typeMismatch
              }
            }

            if (e.target.validity.patternMismatch) {
              if ($(this).data('error-pattern-mismatch')) {
                msg = $(this).data('error-pattern-mismatch')
              } else {
                msg = settings.patternMismatch
              }
            }

            if (e.target.validity.tooLong) {
              if ($(this).data('error-pattern-too-long')) {
                msg = $(this).data('error-pattern-too-long')
              } else {
                msg = settings.tooLong
              }
            }

            if (e.target.validity.rangeUnderflow) {
              if ($(this).data('error-pattern-range-underflow')) {
                msg = $(this).data('error-pattern-range-underflow')
              } else {
                msg = settings.rangeUnderflow
              }
            }

            if (e.target.validity.rangeOverflow) {
              if ($(this).data('error-pattern-range-overflow')) {
                msg = $(this).data('error-pattern-range-overflow')
              } else {
                msg = settings.rangeUOverflow
              }
            }

            if (e.target.validity.stepMismatch) {
              if ($(this).data('error-step-mismatch')) {
                msg = $(this).data('error-step-mismatch')
              } else {
                msg = settings.stepMismatch
              }
            }

            if (!msg) {
              if ($(this).data('error-generic')) {
                msg = $(this).data('error-generic')
              } else {
                msg = settings.generic
              }
            }

            if (msg) {
              if (e.target.tagName.toUpperCase() === 'INPUT' || e.target.tagName.toUpperCase() === 'TEXTAREA') {
                e.target.setCustomValidity(msg)
              }

              $(this).closest('.form-group').addClass('error')

              $(this).closest('.form-group').find('.statut').data('toggle', 'tooltip')
              $(this).closest('.form-group').find('.statut').data('placement', 'bottom')
              $(this).closest('.form-group').find('.statut').data('title', msg)
              $(this).closest('.form-group').find('.statut').tooltip()

              if (settings.animate === true) {
                $(this).addClass('animated ' + settings.animateType)
              }

              if (settings.alert === true && $('#' + settings.alertId).length === 0) {
                settings.alertMessage = msg
                $('body').prepend(
                  global.htmlAlert(settings.alertType, settings.alertId, settings.alertMessage)
                )
                if (settings.alertDelay > 0) {
                  setTimeout(function () {
                    $('#' + settings.alertId).fadeOut().remove()
                  }, settings.alertDelay)
                }
              }
            } else {
              $(this).closest('.form-group').removeClass('error')
            }
          } else {
            $(this).closest('.form-group').removeClass('error')
          }
        }
      })
    })
  }
}(jQuery))
