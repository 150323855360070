import jQuery from 'jquery'
import './alert.js'
import * as Swiper from 'swiper/swiper-bundle.js'

var lazyLoadPageArticles = false

jQuery(document).ready(function($) {
    /* Choix de l'index */
    $('body #letterIndex').on('click', 'a', function(e) {
        e.preventDefault()

        $('body #letterIndex li').removeClass('active')
        $(this).closest('li').addClass('active')

        var href = $(this).attr('href')
        $('#voirPlusArticle').data('index', $(this).data('index'))
        $('#voirPlusArticle').data('page', 2)

        $.ajax({
            url: href
        }).done(function(data) {
            $('#articlesContent').html(data.view)
            if (data.total > $('.unArticle').length) {
                $('#voirPlusArticle').show()
            } else {
                $('#voirPlusArticle').hide()
            }
        })
    })

    /* Voir plus d'article */
    $('body').on('click', '#voirPlusArticle', function(e) {
        e.preventDefault()
        if (lazyLoadPageArticles === false) {
            var href = $(this).data('href')
            var currentPage = $(this).data('page')
            var index = $(this).data('index')
            $('#voirPlusArticle i').addClass('rotating')
            lazyLoadPageArticles = true

            $.ajax({
                url: href,
                data: {
                    'page': currentPage,
                    'index': index
                }
            }).done(function(data) {
                lazyLoadPageArticles = false
                $('#articlesContent').append(data.view)
                $('#voirPlusArticle i').removeClass('rotating')
                $('#voirPlusArticle').data('page', $('#voirPlusArticle').data('page') + 1)
                if (data.total > $('.unArticle').length) {
                    $('#voirPlusArticle').show()
                } else {
                    $('#voirPlusArticle').hide()
                }
            })
        }
    })

    /* Ajouter un article en favoris */
    $('body').on('click', '.articleFavoris', function(e) {
        e.preventDefault()

        var href = $(this).attr('href')
        var id = $(this).data('favoris')
        var icon = $(this).find('i')
        var that = $(this)
        $.ajax({
            url: href
        }).done(function(data) {
            if (data.redirect) {
                window.location.assign(data.redirect)
            } else {
                if (data.statut === false) {
                    icon.text('favorite_border')
                    $('.articleFavoris[data-favoris="' + id + '"] i').text('favorite_border')
                    if (that.attr('data-remove')) {
                        that.closest('.card-article-content').remove()
                    }
                    $(this).alert({
                        delay: 0,
                        message: "L'article a bien été supprimé de vos favoris.",
                        type: 'warning',
                        html: '',
                        id: ''
                    })
                } else {
                    icon.text('favorite')
                    $('.articleFavoris[data-favoris="' + id + '"] i').text('favorite')
                    $(this).alert({
                        delay: 0,
                        message: "L'article a bien été ajouté à vos favoris.",
                        type: 'success',
                        html: '',
                        id: 'alert_article_favoris'
                    })
                }
            }
        })
    })

    /* Désactiver le click au swipe */
    $('#letterIndexContent').bind('touchstart', function() {
        $('#letterIndexContent a').off('click')
    })

    /* Réactive le click a la fin du swipe */
    $('#letterIndexContent').bind('touchend', function() {
        $('#letterIndexContent a').on('click')
        startPosition = null
    })

    var startPosition = null
        /* Detect le swipe */
    $('#letterIndexContent').bind('touchmove', function(e) {
        var maxSize = $('#letterIndex').width()
        var screenSize = $(this).width()
        var maxSwipe = -(maxSize - screenSize)
        if (startPosition === null) {
            startPosition = e.originalEvent.touches[0].pageX - parseFloat($(this).children('#letterIndex').css('marginLeft'))
        }
        var xPos = e.originalEvent.touches[0].pageX

        var moveLeft = xPos - startPosition

        if (moveLeft > 0) {
            moveLeft = 0
        } else if (moveLeft < maxSwipe) {
            moveLeft = maxSwipe
        }

        $(this).children('#letterIndex').css('marginLeft', moveLeft + 'px')
    })

    /* replace l'index au resize */
    $(window).resize(function() {
        $('#letterIndex').css('marginLeft', 0)
    })

    if ($('.swiper-container-article').length > 0) {
        const breakpoint = window.matchMedia('(min-width:993px)')
        let mySwiper

        const breakpointChecker = function() {
            if (mySwiper !== undefined) mySwiper.destroy(true, true)
            return enableSwiper()
        }

        const enableSwiper = function() {
            mySwiper = new Swiper('.swiper-container-article', {
                direction: 'horizontal',
                loop: true,
                spaceBetween: 30,
                pagination: {
                    el: '.swiper-pagination'
                },
                allowSlidePrev: true,
                allowSlideNext: true,
                slidesPerView: 1,
                breakpoints: {
                    993: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                        allowSlidePrev: false,
                        allowSlideNext: false
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                        allowSlidePrev: true,
                        allowSlideNext: true
                    }
                },
                on: {
                    imagesReady: function() {
                        // Load index position on article page after init swiper
                        if ($('#pagesArticles').length > 0) {
                            var fromArticle = $('#pagesArticles').data('from')
                            if (fromArticle) {
                                var top = ($('[data-article="' + fromArticle + '"]').offset().top - ($(window).height() / 2)) + $('[data-article="' + fromArticle + '"]').height()
                                $('html,body').animate({
                                    scrollTop: top
                                }, 'slow')
                            }
                        }
                    }
                }
            })
        }

        enableSwiper()

        breakpoint.addListener(breakpointChecker)
    }
})