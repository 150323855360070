global.htmlAlert = function (type, id, content) {
  switch (type) {
    case 'infos':
      return '<div ' +
        'class="alert alert-' + type + '" ' +
        'id="' + id + '" role="alert">' +
        '<i class="material-icons">notifications_none</i>' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
          '<span aria-hidden="true"><i class="material-icons">clear</i></span>' +
        '</button>' +
        content +
        '</div>'
    case 'danger':
      return '<div ' +
        'class="alert alert-' + type + '" ' +
        'id="' + id + '" role="alert">' +
        '<i class="material-icons">highlight_off</i>' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
          '<span aria-hidden="true"><i class="material-icons">clear</i></span>' +
        '</button>' +
        content +
        '</div>'
    case 'success':
      return '<div ' +
        'class="alert alert-' + type + '" ' +
        'id="' + id + '" role="alert">' +
        '<i class="material-icons">done</i>' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
          '<span aria-hidden="true"><i class="material-icons">clear</i></span>' +
        '</button>' +
        content +
        '</div>'
    case 'warning':
      return '<div ' +
        'class="alert alert-' + type + '" ' +
        'id="' + id + '" role="alert">' +
        '<i class="material-icons">priority_high</i>' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
          '<span aria-hidden="true"><i class="material-icons">clear</i></span>' +
        '</button>' +
        content +
        '</div>'
    default:
      return '<div ' +
        'class="alert alert-default" ' +
        'id="' + id + '" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
          '<span aria-hidden="true"><i class="material-icons">clear</i></span>' +
        '</button>' +
        content +
        '</div>'
  }
}
