import jQuery from 'jquery'
// import './select.js'
import './html5cvm.js'
import './alert.js'
import './pageArticles.js'
import './pageOffres.js'
import './pageEntreprise.js'
import './annonce.js'
import './pageAnnonce.js'
import './monCompteCandidat.js'
import './creerAlerte.js'
import './creerNewsletter.js'
import './monCompteRecruteur.js'
import './search.js'
import './modalClient.js'
import 'bootstrap/js/dist/tooltip'
import 'select2/dist/js/select2'
import 'jquery-ui/ui/widgets/autocomplete'
import 'swiper'

function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
    var separator = uri.indexOf('?') !== -1 ? '&' : '?'
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + '=' + value + '$2')
    } else {
        return uri + separator + key + '=' + value
    }
}

if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    var msViewportStyle = document.createElement('style')
    msViewportStyle.appendChild(
        document.createTextNode(
            '@-ms-viewport{width:auto!important}'
        )
    )
    document.querySelector('head').appendChild(msViewportStyle)
}

function showModal(id, type) {
    localStorage.setItem(type, (new Date()).getDate())
    if (type === 'client') {
        jQuery('#' + id).on('shown.bs.modal', function(e) {
            jQuery('body').removeClass('modal-open')
        })
        jQuery('#' + id).modal({
            show: true,
            backdrop: false
        })
    } else {
        jQuery('#' + id).modal('show')
    }
}

if (document.readyState !== 'loading') {
    if (jQuery('div.modal[data-modal-auto]').length > 0) {
        jQuery('div.modal[data-modal-auto]').each(function(i) {
            let delay = jQuery(this).data('delay')
            let id = jQuery(this).attr('id')
            var boolValue = (/true/i).test(jQuery(this).data('ilimiter'))
            if ((parseInt(localStorage.getItem(jQuery(this).data('type'))) !== parseInt(new Date().getDate())) || (boolValue) || (parseInt(jQuery(this).data('ilimiter')) === 1)) {
                let type = jQuery(this).data('type')
                setTimeout(function() {
                    showModal(id, type)
                }, parseInt(delay))
            }
        })
    }
} else {
    document.addEventListener('DOMContentLoaded', function() {
        if (jQuery('div.modal[data-modal-auto]').length > 0) {
            jQuery('div.modal[data-modal-auto]').each(function(i) {
                let delay = jQuery(this).data('delay')
                let id = jQuery(this).attr('id')
                var boolValue = (/true/i).test(jQuery(this).data('ilimiter'))
                if ((parseInt(localStorage.getItem(jQuery(this).data('type'))) !== parseInt(new Date().getDate())) || (boolValue) || (parseInt(jQuery(this).data('ilimiter')) === 1)) {
                    let type = jQuery(this).data('type')
                    setTimeout(function() {
                        showModal(id, type)
                    }, parseInt(delay))
                }
            })
        }
    })
}

jQuery(document).ready(function($) {

    if ($('#user_recruteur_register_email_error').length > 0) {
        $('body').on('click', '.open-register', function(e) {
            e.preventDefault();
            $('.row-formulaire-inscription').removeClass('d-none');
            var target = $(this).data('target')
            $('.row-formulaire-inscription .btn-connexion').attr('href', $('.row-formulaire-inscription .btn-connexion').attr('href') + '?_target_path=' + target);
            $('.row-formulaire-inscription input[name="_target_path"]').val(target);
            $([document.documentElement, document.body]).animate({
                scrollTop: $(".row-formulaire-inscription").offset().top
            }, 2000);
        })

        $('#user_recruteur_register_email').change(function() {
            var value = $(this).val();
            $.ajax({
                url: '/client/check',
                data: { email: value },
                type: 'POST'
            }).done(function(data) {
                if (data['statut'] === 1) {
                    $('#user_recruteur_register_email_error').text('Cette adresse email est déjà utilisée, merci de vous connecter.');
                    $('.btn-connexion').addClass('btn-primary-clignote');
                    $('.btn-connexion').removeClass('d-none');
                } else {
                    $('#user_recruteur_register_email_error').text('');
                    $('.btn-connexion').removeClass('btn-primary-clignote');
                    $('.btn-connexion').addClass('d-none');
                }
            })
        })
    }

    $('body').find('form.control').each(function() {
        $(this).html5cvm({
            generic: 'Merci de saisir tous les champs obligatoires'
        })
    })

    $('[data-toggle="tooltip"]').tooltip()

    $('.js_none').hide()

    $('select[data-widget="select2"]').select2({
        theme: 'bootstrap'
    })

    if ($('#mon_compte_recruteur').length > 0) {
        $('.btnUploadCv').click(function() {
            $('#mon_compte_entreprise_logoFile_file').click()
        })
    }

    $('.help svg').on('click', function() {
        $(this).closest('.help').toggleClass('active')
    })

    $('#client_offres').on('click', '.checkbox-custom', function(e) {
        e.preventDefault()
        e.stopPropagation()
        if ($(this).closest('.checkbox-options').find('input').prop('checked') === false) {
            $('.checkbox-options').find('input').prop('checked', false)
            $(this).closest('.checkbox-options').find('input').prop('checked', true)
        } else {
            $('.checkbox-options').find('input').prop('checked', false)
        }
    })
})

jQuery('body').on('click', '.rotate', function() {
    var i = jQuery(this).find('i')
    if (i.hasClass('rotate')) {
        i.removeClass('rotate')
    } else {
        i.addClass('rotate')
    }
})

var deleteTarget = ''

jQuery('#delete-annonce .modal-footer button').on('click', function(event) {
    var $button = jQuery(event.target)

    if ($button.data('button') === 'delete') {
        window.location.href = deleteTarget
    } else {
        deleteTarget = ''
    }
})

jQuery(document).on('click', 'a[data-target="#delete-annonce"]', function(event) {
    deleteTarget = jQuery(this).data('href')
})

jQuery('#delete-recruteur .modal-footer button').on('click', function(event) {
    var $button = jQuery(event.target)

    if ($button.data('button') === 'delete') {
        window.location.href = deleteTarget
    } else {
        deleteTarget = ''
    }
})

jQuery(document).on('click', 'a[data-target="#delete-recruteur"]', function(event) {
    deleteTarget = jQuery(this).data('href')
})

/* delete alerte */

jQuery('#delete-alerte .modal-footer button').on('click', function(event) {
    var $button = jQuery(event.target)

    if ($button.data('button') === 'delete') {
        window.location.href = deleteTarget
    } else {
        deleteTarget = ''
    }
})

jQuery(document).on('click', 'a[data-target="#delete-alerte"]', function(event) {
    deleteTarget = jQuery(this).data('href')
})

jQuery(document).on('click', '.close-search-collapse', function() {
    jQuery('body').toggleClass('no-scroll')
    jQuery('.m-filter_list').removeClass('rotate')
    jQuery('.search-collapse').toggleClass('show-mobile')
})

jQuery(document).on('click', '.m-alert', function() {
    jQuery('body').removeClass('no-scroll')
    jQuery('.m-filter_list').removeClass('rotate')
    jQuery('.search-collapse').removeClass('show-mobile')
})

var preselect = null
var citySelect = null
jQuery('.auto-city').autocomplete({
    source: function(request, response) {
        jQuery.ajax({
            url: '/city_autocomplete',
            data: {
                term: request.term
            },
            success: function(data) {
                citySelect = null
                var villes = JSON.parse(data.ville)
                var region = JSON.parse(data.region)
                var departement = JSON.parse(data.departement)
                var resp = []
                preselect = null

                jQuery.map(region, function(item) {
                    if (preselect === null) {
                        preselect = {
                            'lat': '',
                            'lng': '',
                            'value': item.nomRegion,
                            'distance': ''
                        }
                    }
                    resp.push({
                        label: item.nomRegion,
                        value: item.nomRegion,
                        object: {
                            'lat': '',
                            'lng': '',
                            'value': item.nomRegion,
                            'distance': ''
                        }
                    })
                })

                jQuery.map(departement, function(item) {
                    if (preselect === null) {
                        preselect = {
                            'lat': item.lat,
                            'lng': item.lng,
                            'value': item.codeDepartement + ' - ' + item.nomDepartement,
                            'distance': item.distance
                        }
                    }
                    resp.push({
                        label: item.codeDepartement + ' - ' + item.nomDepartement,
                        value: item.codeDepartement + ' - ' + item.nomDepartement,
                        object: {
                            'lat': item.lat,
                            'lng': item.lng,
                            'value': item.codeDepartement + ' - ' + item.nomDepartement,
                            'distance': item.distance
                        }
                    })
                })

                jQuery.map(villes, function(item) {
                    if (preselect === null) {
                        preselect = {
                            'lat': item.lat,
                            'lng': item.lng,
                            'value': item.codePostal + ' - ' + item.ville,
                            'distance': '25'
                        }
                    }
                    resp.push({
                        label: item.codePostal + ' - ' + item.ville,
                        value: item.codePostal + ' - ' + item.ville,
                        object: { 'lat': item.lat, 'lng': item.lng, 'value': item.codePostal + ' - ' + item.ville, 'distance': '25' }
                    })
                })

                return response(resp)
            }
        })
    },
    minLength: 1,
    select: function(event, ui) {
        var object = ui.item.object
        preselect = {
            'lat': object.lat,
            'lng': object.lng,
            'value': object.value,
            'distance': object.distance
        }
        citySelect = true
        jQuery(this).val(object.value)
        jQuery(this).closest('form').find('input.search-lat').val(object.lat)
        jQuery(this).closest('form').find('input.search-lng').val(object.lng)
        jQuery(this).closest('form').find('input.search-dist').val(object.distance)

        var current = window.location.href
        current = updateQueryStringParameter(current, 'lat', object.lat)
        current = updateQueryStringParameter(current, 'lng', object.lng)
        current = updateQueryStringParameter(current, 'dist', object.distance)
        window.history.pushState(null, '', current)
    },
    close: function(event, ui) {
        if (citySelect === null) {
            jQuery(this).val(preselect.value)
            jQuery(this).closest('form').find('input.search-lat').val(preselect.lat)
            jQuery(this).closest('form').find('input.search-lng').val(preselect.lng)
            jQuery(this).closest('form').find('input.search-dist').val(preselect.distance)

            var current = window.location.href
            current = updateQueryStringParameter(current, 'lat', preselect.lat)
            current = updateQueryStringParameter(current, 'lng', preselect.lng)
            current = updateQueryStringParameter(current, 'dist', preselect.distance)
            window.history.pushState(null, '', current)
        }
    }
})

jQuery('.auto-city').on('change', function() {
    if (preselect === null || jQuery(this).val().length !== preselect.value.length) {
        jQuery(this).closest('form').find('input.search-lat').val('')
        jQuery(this).closest('form').find('input.search-lng').val('')
        jQuery(this).closest('form').find('input.search-dist').val('')

        var current = window.location.href
        current = updateQueryStringParameter(current, 'lat', '')
        current = updateQueryStringParameter(current, 'lng', '')
        current = updateQueryStringParameter(current, 'dist', '')
        window.history.pushState(null, '', current)
    }
})

jQuery('body').on('click', '#cookieButton', function() {
    jQuery.ajax({
        url: '/cookieLegal'
    }).done(function() {
        jQuery('#cookies_legal').hide()
    })
    return false
})

jQuery('body').on('change', '#rgpd_ad', function() {
    var statut = 0
    if (this.checked) {
        statut = 1
    }
    jQuery.ajax({
        url: '/rgpd_ad?statut=' + statut
    })
    return true
})

jQuery('body').on('change', '#rgpd_behavior', function() {
    var statut = 0
    if (this.checked) {
        statut = 1
    }
    jQuery.ajax({
        url: '/rgpd_behavior?statut=' + statut
    })
    return true
})

jQuery('#modal-cookie').on('hidden.bs.modal', function(e) {
    jQuery.ajax({
        url: '/cookieLegal'
    }).done(function() {
        jQuery('#cookies_legal').hide()
    })
})

jQuery('body').on('click', '[data-max-height]', function(e) {
    var focus = jQuery(this).data('max-height')
    jQuery(this).parent().hide()
    jQuery('.' + focus).css('max-height', '')
})