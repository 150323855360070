import jQuery from 'jquery'
import './alert_html.js'

(function ($) {
  $.fn.alert = function (options) {
    var vars = $.extend({
      delay: 0,
      message: '',
      type: 'success',
      html: '',
      id: $.now()
    }, options)

    var root = this

    this.construct = function () {
      createHtml()

      $('body').prepend(vars.html)

      if (vars.delay > 0) {
        setTimeout(function () {
          root.find('div#' + vars.id).alert('close')
        }, vars.delay)
      }
    }

    var createHtml = function () {
      vars.html = global.htmlAlert(vars.type, vars.id, vars.message)
    }

    this.construct(options)
  }
}(jQuery))
