import jQuery from 'jquery'
import * as Swiper from 'swiper/swiper-bundle.js';






$(document).ready(function() {
    if ($('#entreprise').length > 0) {


        if ($('.swiper-container-event').length > 0) {

            var mySwiper = new Swiper('.swiper-container-event', {
                slidesPerView: 1,
                spaceBetween: 5,
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 5,
                    },
                },
                pagination: {
                    el: '.swiper-pagination-event',
                    clickable: true,
                },

            });
        }

        if ($('.swiper-container-lecture').length > 0) {

            var mySwiper = new Swiper('.swiper-container-lecture', {
                slidesPerView: 1,
                spaceBetween: 5,
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 5,
                        allowSlidePrev: false,
                        allowSlideNext: false
                    },
                },
                pagination: {
                    el: '.swiper-pagination-lecture',
                    clickable: true,
                },

            });
        }
        // Gets the video src from the data-src on each button

        var $videoSrc;
        $('.video-btn').click(function() {
            $videoSrc = $(this).data("src");
            console.log($videoSrc);
        });
        // when the modal is opened autoplay it  
        $('#myModalVideo').on('shown.bs.modal', function(e) {

                // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
                $("#video").attr('src', $videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0");
            })
            // stop playing the youtube video when I close the modal
        $('#myModalVideo').on('hide.bs.modal', function(e) {
            // a poor man's stop video
            $("#video").attr('src', $videoSrc);
        })

        var slideIndex = 1;
        $('.gallery-1').click(function() {
            slideIndex = 1
            showSlides(1);
        });

        $('.gallery-2').click(function() {
            slideIndex = 2;
            showSlides(2);
        });

        $('.gallery-3').click(function() {
            slideIndex = 3;
            showSlides(3);
        });
        // Next/previous controls
        $('.prev-img').click(function() {
            showSlides(slideIndex - 1);
        });

        $('.next-img').click(function() {
            showSlides(slideIndex + 1);
        });

        function showSlides(n) {
            var i;
            var slides = document.getElementsByClassName("mySlides");
            if (n > slides.length) { slideIndex = 1 } else if (n < 1) { slideIndex = slides.length } else { slideIndex = n }
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            slides[slideIndex - 1].style.display = "block";

        }


        function myFunction() { window.pageYOffset > sticky ? adheader.classList.add("stick") : adheader.classList.remove("stick") };

        var adheader = document.getElementById("menu")
        if (typeof(element) != 'undefined' && element != null)
        {
            window.onscroll = function() { myFunction() };
            var sticky = adheader.offsetTop;
            myFunction();
        }

        $(".lien").click(function() {
            var ancre = $(this).attr("href");
            var fix = $('#menu').height();
            if (!$('#menu').hasClass('stick')) {
                fix = $('#menu').height() + 180;
            }
            $("html, body").animate({
                scrollTop: $(ancre).offset().top - fix
            }, "slow");
            return false;
        });
    }

    if ($('#entreprise').length > 0) {
        $('body').on('click', '#add-cv', function() {
            $('#annonce_postuler_spontannee_cvFile_file').trigger('click')
        })
        $('body').on('click', '#delMonCompte', function() {
            $('#delMonCompteAlert').addClass('show')
        })
        $('body').on('click', '#cvMonCompte', function() {
            $('#cvMonCompteAlert').addClass('show')
        })
        $('#annonce_postuler_spontannee_cvFile_file').on('change', function() {
            var filename = $(this).val()
            var lastIndex = filename.lastIndexOf('\\')
            if (lastIndex >= 0) {
                filename = filename.substring(lastIndex + 1)
            }
            $('input[name="fake_cv"]').val(filename)
        })
    }

    if ($('#entreprise').length > 0) {
        var $collectionHolder;

        // setup an "add a tag" link
        var $addTagButton = $('<button  class="bt_cv" id="bt_doc" type="button" name="cv">Ajouter un document</button>');
        var $newLinkLi = $('<li></li>').append($addTagButton);
        // Get the ul that holds the collection of tags
        $collectionHolder = $('ul.documents');

        // add the "add a tag" anchor and li to the tags ul
        $collectionHolder.append($newLinkLi);

        // count the current form inputs we have (e.g. 2), use that as the new
        // index when inserting a new item (e.g. 2)
        $collectionHolder.data('index', $collectionHolder.find(':input').length);

        $addTagButton.on('click', function(e) {
            // add a new tag form (see next code block)
            addDocForm($collectionHolder, $newLinkLi);
        });

        function addDocForm($collectionHolder, $newLinkLi) {
            // Get the data-prototype explained earlier
            var prototype = $collectionHolder.data('prototype');

            // get the new index
            var index = $collectionHolder.data('index');

            var newForm = prototype;
            newForm = newForm.replace(/__name__/g, index);
            $collectionHolder.data('index', index + 1);
            var $newFormLi = $('<li></li>').append(newForm);
            $newFormLi.find(".vich-file").hide();
            $newFormLi.find("#annonce_postuler_spontannee_candidatDocuments_" + index + "_document").closest('.form-group').hide();
            $newLinkLi.before($newFormLi);
            $("<i class='material-icons'>delete</i>").insertAfter("#annonce_postuler_spontannee_candidatDocuments_" + index + "_document");
            $('#annonce_postuler_spontannee_candidatDocuments_' + index + '_documentFile_file').trigger('click');
        }
        $('ul.documents').on('change', 'input[type=file]', function() {

            var id = $(this).attr('id');
            var input = id.replace("File_file", '');
            var filename = $(this).val()
            var lastIndex = filename.lastIndexOf('\\')
            if (lastIndex >= 0) {
                filename = filename.substring(lastIndex + 1)
            }
            $('#' + input).val(filename)
            $('#' + input).closest('.form-group').show();



        })

        $('ul.documents').on('click', 'i', function() {
            $(this).parent().parent().parent().remove();
        });
    }


})