import jQuery from 'jquery'

jQuery(document).ready(function ($) {
  $('body').on('change', '.commander', function () {
    window.location.href = '/recruteur/panier/' + $(this).val()
  })

  $('body').on('change', '#mon_compte_entreprise_logoFile_file', function () {
    $(this).closest('.custom-file').find('label').text(this.files.item(0).name)
  })
})
