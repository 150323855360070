import jQuery from 'jquery'

function stickyBanner (banner, sticky) {
  if (window.pageYOffset >= sticky) {
    banner.classList.remove('d-none')
    banner.style.position = 'fixed'
    banner.style.top = 0
    banner.style.left = 0
  } else {
    banner.classList.add('d-none')
  }
}

jQuery(document).ready(function ($) {
  if ($('#titre-annonce-sticky').length > 0) {
    var titre = document.querySelector('#titre-annonce')
    var banner = document.querySelector('#titre-annonce-sticky')
    var sticky = titre.offsetTop

    window.onscroll = function () {
      stickyBanner(banner, sticky)
    }
  }
  if($('#directions_contact').length > 0) {
    $('#directions_contact').on('click', '.attach-click', function () {
      $('#directions_contact .attach-hide input').click();
      $('#directions_contact_offreFile_file').on('change', function () {
        var filename = $(this).val()
        var lastIndex = filename.lastIndexOf('\\')
        if (lastIndex >= 0) {
          filename = filename.substring(lastIndex + 1)
        }
        $('input[name="fake-offre"]').val(filename)
      })
    })
    if($('.modal-directions-contact').length > 0) {
      $('#modal-directions-contact').modal('show');
    }
  }
})