import jQuery from 'jquery'
import './alert.js'
import 'bootstrap/js/dist/modal'

function validateEmail (email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

jQuery('#modal-alert').on('show.bs.modal', function () {
  var button = jQuery(this).find('[data-max-height]')
  jQuery(this).find('[data-max-height]').parent().show()
  var focus = button.data('max-height')
  jQuery('.' + focus).css('max-height', '35px')
})

jQuery(document).ready(function ($) {
  if ($('div.modal[data-type="client"]').length > 0) {
    $(document).on('click', 'input[name="submit_client"]', function (e) {
      var email = $(this).closest('form').find('input[name="modal[email]"]').val()
      var modalId = $(this).closest('div.modal').attr('id')
      var valide = $(this).closest('div.modal').data('valide')
      var valideGtm = $(this).closest('div.modal').data('valide-gtm')
      var formData = {}
      formData['email'] = email

      if (!validateEmail(email)) {
        $(this).alert({
          delay: 0,
          message: 'Merci de saisir une adresse email valide.',
          type: 'warning',
          html: '',
          id: $.now()
        })
        e.preventDefault()
        return false
      }

      if ($(this).closest('form').find('input[name="modal[rgpd]"]').is(':checked') === false) {
        $(this).alert({
          delay: 0,
          message: 'Merci de valider le règlement général sur la protection des données.',
          type: 'warning',
          html: '',
          id: $.now()
        })
        e.preventDefault()
      } else {
        if (email.length === 0) {
          $(this).alert({
            delay: 0,
            message: 'Merci de saisir une adresse email.',
            type: 'warning',
            html: '',
            id: $.now()
          })
          e.preventDefault()
        } else {
          $.ajax({
            url: '/client/nouveau',
            data: {form: formData},
            type: 'POST'
          }).done(function (data) {
            if (data['statut'] === 1) {
              $('#' + modalId).modal('hide')
              $('.modal-backdrop').remove()
              $(this).alert({
                delay: 0,
                message: valide,
                type: 'success',
                html: '',
                id: valideGtm
              })
            } else {
              $(this).alert({
                delay: 0,
                message: data['message'],
                type: 'warning',
                html: '',
                id: $.now()
              })
            }
          })
        }
        e.preventDefault()
      }
    })
  }
})
