import jQuery from 'jquery'

jQuery(document).ready(function($) {
    if ($('#mon_compte_recruteur').length > 0) {
        $('body').on('click', '#add-logo', function() {
            $('#mon_compte_recruteur_gerant_logoFile_file').trigger('click')
        })
        $('#mon_compte_recruteur_gerant_logoFile_file').on('change', function() {
            var filename = $(this).val()
            var lastIndex = filename.lastIndexOf('\\')
            if (lastIndex >= 0) {
                filename = filename.substring(lastIndex + 1)
            }
            $('input[name="fake_cv"]').val(filename)
        })
    }
    $('.btn_download').on('click', function() {
        var id = $(this).data('src');
        $('#' + id).find('li').each(function() {
            window.open($(this).data('src'), '_blank');
            setTimeout(() => { '' }, 500)
        });

    })
})