import jQuery from 'jquery'

var ajaxTimeout = null

function addQueryStringParameter(uri, key, value) {
    var separator = uri.indexOf('?') !== -1 ? '&' : '?'
    return uri + separator + key + '=' + value
}

function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
    var separator = uri.indexOf('?') !== -1 ? '&' : '?'
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + '=' + value + '$2')
    } else {
        return uri + separator + key + '=' + value
    }
}

function getParameterByName(url, name) {
    if (!url) url = window.location.href
    name = name.replace(/[[\]]/g, '\\$&')
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    var results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

jQuery(document).on('click', '.m-filter_list', function() {
    jQuery('body').toggleClass('no-scroll')
    jQuery('.search-collapse').toggleClass('show-mobile')

    if (jQuery('.search-collapse div.row-count').length !== 0) {
        jQuery('.search-collapse div.row-count').remove()
    }

    jQuery('#ajax-search div.row-count').clone().prependTo(jQuery('.search-collapse'))

    jQuery('.search-collapse div.row-count h2').find('span').remove()

    jQuery('.search-collapse div.row-count h2').text(jQuery('.search-collapse div.row-count h2').text() + ' trouvées')
    jQuery(document).scrollTop(0)
})

jQuery('body').on('click', '.form_filter_reset-all', function(e) {
    e.preventDefault()

    jQuery('form[name="form_filter"] input:checkbox').prop('checked', false)
    jQuery('form[name="form_filter"] input:radio').prop('checked', false)

    var formFilter = jQuery('form.form-search').serialize()

    if (jQuery('form[name="form_filter"]').length > 0) {
        formFilter = jQuery('form[name="form_filter"], form.form-search').serialize()
    }

    jQuery('#ajax-search .row-count').html('')
    jQuery('#ajax-search-entreprise .row-count').html('')
    jQuery('#ajax-search .content-offre').html('')
    jQuery('#ajax-search-entreprise .content-entreprise').html('')
    jQuery('#ajax-search .row-pagination').html('')

    clearTimeout(ajaxTimeout)

    ajaxTimeout = setTimeout(function() {
        jQuery('#ajax-loading').addClass('show')
        jQuery.ajax({
            url: '/recherche/ajax',
            type: 'POST',
            data: formFilter,
            cache: false,
            dataType: 'JSON',
            success: function(data, textStatus, jqXHR) {
                jQuery('#ajax-loading').removeClass('show')
                ajaxSearchSuccess(data, formFilter)
            }
        })
    }, 500)
})

jQuery('body').on('change', 'input[name="keyword"],input[name=""]', function() {
    var ent = jQuery('input[name="entreprise"]').val()
    var keyword = jQuery('input[name="keyword"]').val()
    if (keyword.indexOf(ent) === -1) {
        jQuery('input[name="entreprise"]').val('')
    }

    var lieu = jQuery('input[name="lieu"]').val().trim()
    if (lieu.length <= 0) {
        jQuery('input[name="lat"]').val('')
        jQuery('input[name="lng"]').val('')
        jQuery('input[name="dist"]').val('')
    }

    var formFilter = jQuery('form.form-search').serialize()

    if (jQuery('form[name="form_filter"]').length > 0) {
        formFilter = jQuery('form[name="form_filter"], form.form-search').serialize()
    }

    var current = window.location.href.split(/[?#]/)[0]
    var params = formFilter.split('&')
    var i = 0
    for (i = 0; i < params.length; i++) {
        var key = params[i].split('=')
        current = addQueryStringParameter(current, key[0], key[1])
    }
    window.history.pushState(null, '', current)
})

jQuery('form.form-search').on('submit', function(e) {
    if (jQuery('#ajax-search').length > 0) {
        e.preventDefault()
        var ent = jQuery('input[name="entreprise"]').val()
        var keyword = jQuery('input[name="keyword"]').val()

        if (keyword.indexOf(ent) === -1) {
            jQuery('input[name="entreprise"]').val('')
        }

        var formFilter = jQuery('form[name="form_filter"], form.form-search').serialize()
        jQuery('#ajax-search .row-count').html('')
        jQuery('#ajax-search-entreprise .row-count').html('')
        jQuery('#ajax-search .content-offre').html('')
        jQuery('#ajax-search-entreprise .content-entreprise').html('')
        jQuery('#ajax-search .row-pagination').html('')

        clearTimeout(ajaxTimeout)

        ajaxTimeout = setTimeout(function() {
            jQuery('#ajax-loading').addClass('show')
            jQuery.ajax({
                url: '/recherche/ajax',
                type: 'POST',
                data: formFilter,
                cache: false,
                dataType: 'JSON',
                success: function(data, textStatus, jqXHR) {
                    jQuery('#ajax-loading').removeClass('show')
                    ajaxSearchSuccess(data, formFilter)
                }
            })
        }, 500)
    }
})

jQuery('form[name="form_filter"] input, form[name="form_filter"] label').on('click', function(e) {
    var ent = jQuery('input[name="entreprise"]').val()
    var keyword = jQuery('input[name="keyword"]').val()

    if (keyword.indexOf(ent) === -1) {
        jQuery('input[name="entreprise"]').val('')
    }

    var formFilter = jQuery('form[name="form_filter"], form.form-search').serialize()
    jQuery('#ajax-search .row-count').html('')
    jQuery('#ajax-search-entreprise .row-count').html('')
    jQuery('#ajax-search .content-offre').html('')
    jQuery('#ajax-search .content-entreprise').html('')
    jQuery('#ajax-search .row-pagination').html('')

    clearTimeout(ajaxTimeout)

    ajaxTimeout = setTimeout(function() {
        jQuery('#ajax-loading').addClass('show')
        jQuery.ajax({
            url: '/recherche/ajax',
            type: 'POST',
            data: formFilter,
            cache: false,
            dataType: 'JSON',
            success: function(data, textStatus, jqXHR) {
                jQuery('#ajax-loading').removeClass('show')
                ajaxSearchSuccess(data, formFilter)
            }
        })
    }, 500)
})

jQuery('body').on('click', 'ul.pagination li a', function(e) {
    var path = window.location.href

    if (path.indexOf('/recherche') !== -1) {
        e.preventDefault()

        var formFilter = jQuery('form[name="form_filter"], form.form-search').serialize()
        jQuery('#ajax-search .row-count').html('')
        jQuery('#ajax-search-entreprise .row-count').html('')
        jQuery('#ajax-search .content-offre').html('')
        jQuery('#ajax-search-entreprise .content-entreprise').html('')
        jQuery('#ajax-search .row-pagination').html('')

        var page = getParameterByName(jQuery(this).attr('href'), 'page')

        clearTimeout(ajaxTimeout)

        ajaxTimeout = setTimeout(function() {
            jQuery('#ajax-loading').addClass('show')
            jQuery.ajax({
                url: '/recherche/ajax?page=' + page,
                type: 'POST',
                data: formFilter,
                cache: false,
                dataType: 'JSON',
                success: function(data, textStatus, jqXHR) {
                    jQuery('#ajax-loading').removeClass('show')
                    ajaxSearchSuccess(data, formFilter)

                    var path = window.location.href

                    var nextPath = addQueryStringParameter(path, 'page', page)
                    window.scrollTo(0, 0)
                    window.history.pushState(null, '', nextPath)
                }
            })
        }, 500)
    }
})

function ajaxSearchSuccess(data, formFilter) {
    var current = window.location.href.split(/[?#]/)[0]
    var params = formFilter.split('&')
    var i = 0
    for (i = 0; i < params.length; i++) {
        var key = params[i].split('=')
        current = addQueryStringParameter(current, key[0], key[1])
    }

    jQuery('#ajax-search .row-count').html(data.html_header)
    jQuery('#ajax-search-entreprise .row-count').html(data.html_header_entreprise)
    jQuery('#ajax-search .content-offre').html(data.html)
    jQuery('#ajax-search-entreprise .content-entreprise').html(data.html_entreprise)
    jQuery('#ajax-search .row-pagination').html(data.html_pagination)

    if (jQuery('.search-collapse div.row-count').length !== 0) {
        jQuery('.search-collapse div.row-count').remove()
    }

    jQuery('#ajax-search div.row-count').clone().prependTo(jQuery('.search-collapse'))

    jQuery('.search-collapse div.row-count h2').find('span').remove()

    jQuery('.search-collapse div.row-count h2').text(jQuery('.search-collapse div.row-count h2').text() + ' trouvées')

    jQuery('input.auto-city').val(data.lieu)
    if (data.lat === 'null') {
        jQuery('input.search-lat').val('')
        jQuery('input.search-lng').val('')
        jQuery('input.search-dist').val('')
    } else {
        jQuery('input.search-lat').val(data.lat)
        jQuery('input.search-lng').val(data.lng)
        jQuery('input.search-dist').val(data.dist)
    }

    current = updateQueryStringParameter(current, 'lieu', data.lieu)
    current = updateQueryStringParameter(current, 'lat', data.lat)
    current = updateQueryStringParameter(current, 'lng', data.lng)
    current = updateQueryStringParameter(current, 'dist', data.dist)
    window.history.pushState(null, '', current)
    jQuery('.auto-city').autocomplete('close')
}

jQuery('body').on('click', 'a.populaire', function(e) {
    e.preventDefault()
    var keyword = jQuery(this).data('keyword')
    jQuery('input[name="keyword"]').val(keyword)
    var ent = jQuery('input[name="entreprise"]').val()
    if (keyword.indexOf(ent) === -1) {
        jQuery('input[name="entreprise"]').val('')
    }

    var lieu = jQuery('input[name="lieu"]').val().trim()
    if (lieu.length <= 0) {
        jQuery('input[name="lat"]').val('')
        jQuery('input[name="lng"]').val('')
        jQuery('input[name="dist"]').val('')
    }

    var formFilter = jQuery('form.form-search').serialize()

    if (jQuery('form[name="form_filter"]').length > 0) {
        formFilter = jQuery('form[name="form_filter"], form.form-search').serialize()
    }

    var current = window.location.href.split(/[?#]/)[0]
    var params = formFilter.split('&')
    var i = 0
    for (i = 0; i < params.length; i++) {
        var key = params[i].split('=')
        current = addQueryStringParameter(current, key[0], key[1])
    }
    window.history.pushState(null, '', current)

    if (jQuery('#ajax-search').length > 0) {
        e.preventDefault()

        if (keyword.indexOf(ent) === -1) {
            jQuery('input[name="entreprise"]').val('')
        }

        formFilter = jQuery('form[name="form_filter"], form.form-search').serialize()
        jQuery('#ajax-search .row-count').html('')
        jQuery('#ajax-search-entreprise .row-count').html('')
        jQuery('#ajax-search .content-offre').html('')
        jQuery('#ajax-search-entreprise .content-entreprise').html('')
        jQuery('#ajax-search .row-pagination').html('')

        clearTimeout(ajaxTimeout)

        ajaxTimeout = setTimeout(function() {
            jQuery('#ajax-loading').addClass('show')
            jQuery.ajax({
                url: '/recherche/ajax',
                type: 'POST',
                data: formFilter,
                cache: false,
                dataType: 'JSON',
                success: function(data, textStatus, jqXHR) {
                    jQuery('#ajax-loading').removeClass('show')
                    ajaxSearchSuccess(data, formFilter)
                }
            })
        }, 500)
    }
})